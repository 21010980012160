import HttpService from "./HttpService";

export const LoadProfile = (guard) => {
  const http = new HttpService();
  let profileUrl = guard == "users" ? "profile" : "users/view-profile";
  return http
    .getData(profileUrl, "user-token", guard)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
