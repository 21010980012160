import { HeaderWrapper } from "./Header.style.js";
import React from "react";
import { Button } from "antd";
import Link from "next/link";
import { useRouter } from "next/router";
import { useMediaQuery } from "react-responsive";
import { useState, useEffect } from "react";
import { LogoutAction } from "../../../redux/actions/AuthActions.js";
import { useDispatch, useSelector } from "react-redux";
import { FaPowerOff } from "react-icons/fa";
import MobileHeader from "./Mobile/MobileHeader.js";
import Image from "next/image";
import logo_easycapital from "../../../../public/logo_easycapital.svg";
import logo_easycapital_white_color from "../../../../public/logo_easycapital_white_color.png";

export default function Header() {
  const router = useRouter();
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(LogoutAction(router));
  };

  const [loading, setloading] = useState(true);

  const [isUserConnected, setIsUserConnected] = useState(false);
  const state = useSelector((state) => state);
  useEffect(() => {
    setIsUserConnected(state?.userDetails?.userProfile?.data != null);
  }, [state]);

  const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 1024 });
    return isDesktop ? children : null;
  };
  const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 1024 });
    return isMobile ? children : null;
  };

  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    setloading(false);
  }, [loading]);

  return !loading && router.asPath !== "/start" ? (
    <>
      <Desktop>
        <HeaderWrapper
          className={
            (scrollPosition != 0 ? "scroll" : "top") +
            (["/securite", "/tarifs"].includes(router.asPath)
              ? "  security"
              : "") +
            (router.asPath == "/start" ? " start" : "")
          }
        >
          <div className="left">
            <Link href="/">
              <div className="logoImage">
                <Image
                  src={
                    !["/securite", "/tarifs"].includes(router.asPath) ||
                    scrollPosition != 0
                      ? logo_easycapital
                      : logo_easycapital_white_color
                  }
                  alt="logo EasyCapital"
                  width={200}
                  height={50}
                />
              </div>
            </Link>
            {isUserConnected && (
              <Button className="button" type="link">
                <Link href="/conformite">Ma conformité</Link>
              </Button>
            )}
            <Button className="button" type="link">
              <Link href="/outil">Outil</Link>
            </Button>
            <Button className="button" type="link">
              <Link href="/nous">Nous</Link>
            </Button>
            <Button className="button" type="link">
              <Link href="/securite">Sécurité</Link>
            </Button>
            <Button className="button" type="link">
              <Link href="/tarifs">
                {isUserConnected ? "Mes abonnements" : "Tarifs"}
              </Link>
            </Button>
            <Button className="button" type="link">
              <Link href="/easynews">Easy News</Link>
            </Button>
          </div>
          <div className="right">
            {!isUserConnected && scrollPosition != 0 && (
              <Button className="bleu" type="Primary" id="startNowBtn">
                <Link href="/start">Commencer maintenant</Link>
              </Button>
            )}
            {isUserConnected ? (
              <Button className="button" onClick={logOut}>
                <FaPowerOff />
              </Button>
            ) : (
              <Button className="button signInBtn">
                <Link href="/signin">Me connecter</Link>
              </Button>
            )}
          </div>
        </HeaderWrapper>
      </Desktop>
      <Mobile>
        <MobileHeader
          isUserConnected={isUserConnected}
          scrollPosition={scrollPosition}
          logOut={logOut}
        />
      </Mobile>
    </>
  ) : (
    <></>
  );
  // else if (router.asPath === "/start") return <StartHeader />;
}
