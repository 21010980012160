import { getCookie } from "cookies-next";

export default class HttpService {
  url = process.env.API_URL + "";
  getData = async (added_url, tokenId = "", guard = "client_users") => {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.getRequestOptions(token);

    return fetch(
      (guard == "users" ? this.url.slice(0, -3) + "crm_v2" : this.url) +
        "/" +
        added_url,
      requestOptions
    ).then((response) => response.json());
  };

  getRequestOptions = (token) => {
    let requestOptions = {
      method: "GET",
      headers: {
        Authorization: "Bearer " + (token ?? ""),
        "Content-type": "application/json",
        "Cache-Control": "private",
      },
    };
    return requestOptions;
  };

  postData = async (
    item,
    added_url,
    tokenId = "user-token",
    pdf = false,
    guard = "client_users"
  ) => {
    const token = await localStorage.getItem(tokenId);
    const requestOptions = this.postRequestOptions(token, item);
    return fetch(
      (guard == "client_users" ? this.url : this.url.slice(0, -3) + "crm_v2") +
        "/" +
        added_url,
      requestOptions
    ).then((response) => (pdf ? response : response.json()));
  };

  postRequestOptions = (token, item) => {
    let requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Method": "GET, POST, OPTIONS",
      },
      body: JSON.stringify(item),
    };

    return requestOptions;
  };

  putData = async (item, added_url) => {
    const token = await localStorage.getItem("user-token");
    const requestOptions = this.putRequestOptions(token, item);
    return fetch(this.url + "/" + added_url, requestOptions).then((response) =>
      response.json()
    );
  };

  putRequestOptions = (token, item) => {
    let requestOptions = {
      method: "PUT",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Method": "GET, PUT, POST, OPTIONS",
      },
      body: JSON.stringify(item),
    };

    return requestOptions;
  };
  deleteData = async (added_url, file = false) => {
    const token = await localStorage.getItem("user-token");
    const requestOptions = this.deleteRequestOptions(token);
    return fetch(process.env.API_URL + added_url, requestOptions).then(
      (response) => (file ? response : response.json())
    );
  };
  
  deleteRequestOptions = (token, item) => {
    let requestOptions = {
      method: "DELETE",
      headers: {
        Authorization: "Bearer " + token,
        "Content-type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Method": "GET, POST, OPTIONS",
        Accept: "application/json",
      },
      body: JSON.stringify(item),
    };
    return requestOptions;
  };
  postFileData = async (item, added_url) => {
    const token = await localStorage.getItem("user-token");
    const requestOptions = this.postFileRequestOptions(token, item);
    return fetch(process.env.API_URL + added_url, requestOptions).then(
      (response) => response
    );
  };
  postFileRequestOptions = (token, item) => {
    let requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Bearer " + token,
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Request-Method": "GET, POST, OPTIONS",
      },
      body: item,
    };
    return requestOptions;
  };
}
