import { useRouter } from "next/router";
import React from "react";
import Header from "../../global/Header/Header";

const Navbar = () => {
  const navbarStyles = {
    position: "fixed",
    height: "73.89px",
    width: "100%",
    backgroundColor: "transparent",
    textAlign: "center",
    transition: "all 250ms ease-in 0s",
    zIndex: "1000",
    top: "0",
    transform: "translateZ('0px')",
  };

  const router = useRouter();

  return (
    <div style={navbarStyles}>
      <Header />
      {/* {!router.asPath.includes("/gestion-de-patrimoine") && <Header />} */}
    </div>
  );
};

export default Navbar;
