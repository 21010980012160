import { HeaderWrapper } from "../Header.style";
import { NavBar, List } from "antd-mobile";
import { Button, Drawer } from "antd";
import { AiOutlineMenu } from "react-icons/ai";
import { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { FaPowerOff } from "react-icons/fa";

export default function MobileHeader({
  isUserConnected,
  scrollPosition,
  logOut,
}) {
  const [visible, setVisible] = useState(false);
  const router = useRouter();

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <HeaderWrapper
      className={
        (scrollPosition > 300 ? "scroll" : "top") +
        (["/securite", "/tarifs"].includes(router.asPath) ? "  security" : "")
      }
    >
      <NavBar
        leftContent={[
          <Link href="/">
            {!["/securite", "/tarifs"].includes(router.asPath) ? (
              <div className="logo">
                <img src="/logo_easycapital_small.svg" alt="logo EasyCapital" />
              </div>
            ) : (
              <div className="whiteLogo">
                <img
                  src="/logo_easycapital_small_white.png"
                  alt="logo EasyCapital"
                />
              </div>
            )}
          </Link>,
        ]}
      >
        <AiOutlineMenu className="iconMenu" onClick={showDrawer} />
      </NavBar>
      <Drawer
        placement="left"
        onClose={onClose}
        visible={visible}
        className="my-drawer"
        closable={false}
      >
        <List>
          <List.Item>
            <Button type="link" onClick={onClose}>
              <Link href="/">
                <div>
                  <img
                    className="logoImage"
                    src="../../../easycapital_logo.svg"
                    alt="logo EasyCapital"
                  />
                </div>
              </Link>
            </Button>
          </List.Item>
          <List.Item>
            <Button type="link" onClick={onClose}>
              <Link href="/outil">Outil</Link>
            </Button>
          </List.Item>
          <List.Item>
            <Button type="link" onClick={onClose}>
              <Link href="/nous">Nous</Link>
            </Button>
          </List.Item>
          <List.Item>
            <Button type="link" onClick={onClose}>
              <Link href="/securite">Sécurité</Link>
            </Button>
          </List.Item>
          <List.Item>
            <Button type="link" onClick={onClose}>
              <Link href="/tarifs">
                {!isUserConnected ? "Tarifs" : "Mes abonnements"}
              </Link>
            </Button>
          </List.Item>
          <List.Item>
            <Button type="link" onClick={onClose}>
              <Link href="/easynews">EasyNews</Link>
            </Button>
          </List.Item>
          {isUserConnected && (
            <List.Item>
              <Button type="link" onClick={onClose}>
                <Link href="/conformite">Ma conformité</Link>
              </Button>
            </List.Item>
          )}
        </List>
        <div className="bottom">
          {isUserConnected ? (
            <FaPowerOff onClick={logOut} />
          ) : (
            <Button type="secondary">
              <Link href="/signin">Me connecter</Link>
            </Button>
          )}
          {!isUserConnected && (
            <Button type="primary" onClick={onClose}>
              <Link href="/start">Commencer maintenant</Link>
            </Button>
          )}
        </div>
      </Drawer>
    </HeaderWrapper>
  );
}
