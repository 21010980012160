import "antd/dist/antd.css";
import "antd-mobile/dist/antd-mobile.css";
import "../styles/globals.scss";
import Navbar from "../components/global/Layout/navbar";
import { useRouter } from "next/router";
import { Provider } from "react-redux";
import { useStore } from "../redux/CreateStore";
import AuthProvider from "../providers/AuthProvider";
import { DefaultSeo, NextSeo } from "next-seo";
import SEO from "../../next-seo.config";
import NextNProgress from "nextjs-progressbar";
import "aos/dist/aos.css";
// require("../styles/variables.less");
import { config } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
config.autoAddCss = false;
import "@ant-design/flowchart/dist/index.css";
import 'chart.js/auto';

function MyApp({ Component, pageProps }) {
  const router = useRouter();
  const store = useStore(pageProps.initialReduxState);

  return (
    <Provider store={store}>
      <DefaultSeo
        {...SEO}
        canonical={
          (
            `https://www.easycapital.fr` +
            (router.asPath === "/" ? "" : router.asPath)
          ).split("?")[0]
        }
        description={
          "Le seul outil de gestion de patrimoine en ligne accessible qui compare toutes les solutions d’investissements immobilières ou financières afin d’optimiser votre fiscalité."
        }
        additionalLinkTags={[
          {
            rel: "icon",
            href: "../logo_easycapital_small.svg",
          },
        ]}
      />
      <NextSeo title={"Easy Capital - Outil de gestion de patrimoine"} />
      <NextNProgress />
      <Navbar />
      <AuthProvider>
        <Component {...pageProps} />
      </AuthProvider>
    </Provider>
  );
}

export default MyApp;
