import { getCookie } from "cookies-next";
import { useRouter } from "next/router";
import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import LoadingSpinner from "../components/global/LoadingSpinner/LoadingSpinner";
import { LoadProfileAction } from "../redux/actions/ProfileActions";

export default function AuthProvider({ children }) {
  const [loaded, setLoaded] = useState(false);

  const router = useRouter();
  const dispatch = useDispatch();

  const logAction = useSelector((state) => state.userAuth);

  useEffect(() => {
    dispatch(LoadProfileAction(getCookie("guard")));
  }, []);

  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(null);
  useEffect(() => {
    if (logAction?.authResponse?.message == "successfully logged out") {
      dispatch(LoadProfileAction(getCookie("guard")));
    }
  }, [logAction]);

  const state = useSelector((state) => state);
  useEffect(() => {
    setLoggedIn(state?.userDetails?.userProfile?.data != null);
    setUser(state?.userDetails?.userProfile?.data);
    //if unauthenticated
    if (state.userDetails.userProfile.data === null) {
      if (router.asPath == "/profile") {
        router.push("/");
      }
      if (router.asPath == "/conformite") {
        router.push("/signin");
      }
    }
    //if authenticated
    else if (state.userDetails.userProfile.data !== undefined) {
      if (["/signin", "/signup"].includes(router.asPath)) {
        router.push("/profile");
      }
    }
  }, [state]);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return loaded ? (
    <>{React.cloneElement(children, { loggedIn, user })}</>
  ) : (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <LoadingSpinner />
    </div>
  );
}
