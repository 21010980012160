import { getCookie } from "cookies-next";
import HttpService from "./HttpService";

export const RegisterUserService = (credentials) => {
  const http = new HttpService();
  let signupUrl = "users/register";
  return http
    .postData(credentials, signupUrl)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const LoginUserService = (credentials, guard) => {
  const http = new HttpService();
  let loginUrl = (guard == "client_users" ? "users/" : "") + "login";
  return http
    .postData(credentials, loginUrl, "user-token", false, guard)
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};

export const LogOutUserService = () => {
  const http = new HttpService();
  let loginUrl = (getCookie("guard") == "client_users" ? "users/" : "") + "logout";
  const tokenId = "user-token";
  return http
    .getData(loginUrl, tokenId, getCookie("guard"))
    .then((data) => {
      return data;
    })
    .catch((error) => {
      return error;
    });
};
