import styled from "styled-components";
import { blue, lightgrey } from "../../../styles/variables.style";

export const HeaderWrapper = styled.div`
  display: flex;
  height: 70px;
  padding: 0px 10%;
  position: sticky;
  background: transparent;
  transition: background 1.5s !important;
  .am-drawer-draghandle {
    width: 0px !important;
  }

  &.scroll {
    background: white;
  }

  &.start {
    background: ${lightgrey};
  }

  &.security.top {
    a {
      color: white;
      &:hover {
        color: white;
      }
    }
    .button {
      color: white;
    }
  }

  > div {
    flex: 1;
    display: flex;
    align-items: center;
  }

  .left {
    display: flex;
    .ant-btn {
      margin-left: 30px;
      font-size: 17px;
      font-weight: bold;

      &:hover {
        font-style: italic;
      }
      a {
        display: flex;
      }

      svg {
        vertical-align: top;
        margin-left: 10px;
        padding-top: 2px;
        height: 25px;
        width: 25px;
        color: #ffa400;
      }
    }
  }

  .right {
    display: flex;
    justify-content: flex-end;
    .Button {
      border: none;
      border-radius: 7px;
      font-size: 17px;
    }
    .profilPic {
      margin-left: 20px;
      height: 50%;
      border-radius: 30px;
    }
    .profil {
      margin-right: 20px;
      border-radius: 30px;
    }
    #startNowBtn {
      width: 350px;
      margin-right: 20px;
      margin-top: -3px;
    }
    .signInBtn {
      margin-top: -3px;
      display: flex;
      justify-content: center;
      border: 1px solid grey !important;
      border-radius: 5px !important;
      padding: 0px 30px 0px 30px;
      height: 39px;

      transition: background 0.5s, border-color 0.5s, color 0.5s !important;
      &:hover {
        font-weight: normal;
        border-color: ${blue} !important;
        background: ${blue};
        color: white !important;
      }
    }
  }

  .button {
    padding: 0;
    border: none;
    background: none;
    color: black;

    &:hover {
      border-radius: 0px;
      border-bottom: solid;
    }
  }

  .logoImage {
    width: 200px;
  }
  .logoImage:hover {
    cursor: pointer;
  }

  .bleu {
    color: white;
    background-color: #4ca6e2;
    width: 250px;
    margin-left: 10%;
    border-width: 0px;
  }

  .test {
    vertical-align: bottom;
  }

  @media (max-width: 1024px) {
    -webkit-transition: "none";
    -webkit-transition: "all 0.2s ease-out";
    -webkit-transform: "translate(XXpx, XXpx)";

    padding: 0px;
    ${'' /* background: linear-gradient(white, white, transparent) !important; */}
    background: white;
    &.top.security {
      background: transparent !important;
      .iconMenu {
        color: white;
      }
    }
    &.scroll {
      ${'' /* background: linear-gradient(white, white, transparent) !important; */}
      background: white;
      .iconMenu {
        color: black;
      }
    }
    .am-navbar {
      background-color: unset;
      .am-navbar-title {
        display: flex;
        justify-content: flex-end;
        svg {
          color: black;
        }
      }
    }
    .logo > img {
      width: 30px;
    }
    .whiteLogo > img {
      width: 60px;
    }
    .logoImage {
      max-height: 70px;
      max-width: 250px;
      padding-right: 0;
    }
    .am-navbar {
      height: 70px;
      .am-navbar-right {
        display: none;
      }
      .am-navbar-title {
        svg {
          margin-top: -5px;
          margin-right: 25px;
          width: 30px;
          height: 30px;
        }
      }
    }
  }
`;
