export const green = "#2CC84D";
export const lightgreen = "#58df73";
export const blue = "#4CA6E2";
export const darkblue = "#1681c9";
export const lightblue = "#7EBDE8";
export const lightgrey = "#F5F5F9";
export const mazarine = "#1A202C;";
export const orange = "#FFA400";
export const lightorange = "#FFC866";
export const grey = "#88898a";
export const yellow = "#ffc000";
export const black = "#101820";
